/* [1] */
/* [2] */
/* [3] */
/* [4] */
/* [5] */
/* [6] */
/* Styles: */
.icon {
  position: relative;
  background: '#ffc643';
  cursor: pointer;
  margin-top: 200px;
  color: black;
  border-radius: 50%;
  height: 400px;
  width: 400px;
  font-size: 2.75em;
  font-weight: bold;
  transition: 0.24s 0.2s;
}

body,
a,
ul,
li {
  list-style: none;
}

.menu {
  position: absolute;
  top: -100px;
  left: -100px;
  border: 300px solid transparent;
  cursor: default;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 1.4s 0.07s;
  z-index: -5;
}

.spread {
  position: absolute;
  top: -320px;
  left: -320px;
  transform-origin: 320px 320px;
  transition: all 0.5s 0.1s;
}

.icon {
  /* Where all the MAGIC happens: */
}
.icon .menu {
  transition:
    transform 0.4s 0.08s,
    z-index 0s 0.5s;
  transform: scale(1);
  z-index: 5;
}
.icon .spread,
.icon .unit {
  transition: all 0.6s;
}
.icon .spread:nth-child(1) {
  transition-delay: 0.02s;
  transform: rotate(57.41379deg);
}
.icon .spread:nth-child(1) .unit {
  transition-delay: 0.04s;
  transform: rotate(662.58621deg);
}
.icon .spread:nth-child(2) {
  transition-delay: 0.04s;
  transform: rotate(69.82759deg);
}
.icon .spread:nth-child(2) .unit {
  transition-delay: 0.08s;
  transform: rotate(650.17241deg);
}
.icon .spread:nth-child(3) {
  transition-delay: 0.06s;
  transform: rotate(82.24138deg);
}
.icon .spread:nth-child(3) .unit {
  transition-delay: 0.12s;
  transform: rotate(637.75862deg);
}
.icon .spread:nth-child(4) {
  transition-delay: 0.08s;
  transform: rotate(94.65517deg);
}
.icon .spread:nth-child(4) .unit {
  transition-delay: 0.16s;
  transform: rotate(625.34483deg);
}
.icon .spread:nth-child(5) {
  transition-delay: 0.1s;
  transform: rotate(107.06897deg);
}
.icon .spread:nth-child(5) .unit {
  transition-delay: 0.2s;
  transform: rotate(612.93103deg);
}
.icon .spread:nth-child(6) {
  transition-delay: 0.12s;
  transform: rotate(119.48276deg);
}
.icon .spread:nth-child(6) .unit {
  transition-delay: 0.24s;
  transform: rotate(600.51724deg);
}
.icon .spread:nth-child(7) {
  transition-delay: 0.14s;
  transform: rotate(131.89655deg);
}
.icon .spread:nth-child(7) .unit {
  transition-delay: 0.28s;
  transform: rotate(588.10345deg);
}
.icon .spread:nth-child(8) {
  transition-delay: 0.16s;
  transform: rotate(144.31034deg);
}
.icon .spread:nth-child(8) .unit {
  transition-delay: 0.32s;
  transform: rotate(575.68966deg);
}
.icon .spread:nth-child(9) {
  transition-delay: 0.18s;
  transform: rotate(156.72414deg);
}
.icon .spread:nth-child(9) .unit {
  transition-delay: 0.36s;
  transform: rotate(563.27586deg);
}
.icon .spread:nth-child(10) {
  transition-delay: 0.2s;
  transform: rotate(169.13793deg);
}
.icon .spread:nth-child(10) .unit {
  transition-delay: 0.4s;
  transform: rotate(550.86207deg);
}
.icon .spread:nth-child(11) {
  transition-delay: 0.22s;
  transform: rotate(181.55172deg);
}
.icon .spread:nth-child(11) .unit {
  transition-delay: 0.44s;
  transform: rotate(538.44828deg);
}
.icon .spread:nth-child(12) {
  transition-delay: 0.24s;
  transform: rotate(193.96552deg);
}
.icon .spread:nth-child(12) .unit {
  transition-delay: 0.48s;
  transform: rotate(526.03448deg);
}
.icon .spread:nth-child(13) {
  transition-delay: 0.26s;
  transform: rotate(206.37931deg);
}
.icon .spread:nth-child(13) .unit {
  transition-delay: 0.52s;
  transform: rotate(513.62069deg);
}
.icon .spread:nth-child(14) {
  transition-delay: 0.28s;
  transform: rotate(218.7931deg);
}
.icon .spread:nth-child(14) .unit {
  transition-delay: 0.56s;
  transform: rotate(501.2069deg);
}
.icon .spread:nth-child(15) {
  transition-delay: 0.3s;
  transform: rotate(231.2069deg);
}
.icon .spread:nth-child(15) .unit {
  transition-delay: 0.6s;
  transform: rotate(488.7931deg);
}
.icon .spread:nth-child(16) {
  transition-delay: 0.32s;
  transform: rotate(243.62069deg);
}
.icon .spread:nth-child(16) .unit {
  transition-delay: 0.64s;
  transform: rotate(476.37931deg);
}
.icon .spread:nth-child(17) {
  transition-delay: 0.34s;
  transform: rotate(256.03448deg);
}
.icon .spread:nth-child(17) .unit {
  transition-delay: 0.68s;
  transform: rotate(463.96552deg);
}
.icon .spread:nth-child(18) {
  transition-delay: 0.36s;
  transform: rotate(268.44828deg);
}
.icon .spread:nth-child(18) .unit {
  transition-delay: 0.72s;
  transform: rotate(451.55172deg);
}
.icon .spread:nth-child(19) {
  transition-delay: 0.38s;
  transform: rotate(280.86207deg);
}
.icon .spread:nth-child(19) .unit {
  transition-delay: 0.76s;
  transform: rotate(439.13793deg);
}
.icon .spread:nth-child(20) {
  transition-delay: 0.4s;
  transform: rotate(293.27586deg);
}
.icon .spread:nth-child(20) .unit {
  transition-delay: 0.8s;
  transform: rotate(426.72414deg);
}
.icon .spread:nth-child(21) {
  transition-delay: 0.42s;
  transform: rotate(305.68966deg);
}
.icon .spread:nth-child(21) .unit {
  transition-delay: 0.84s;
  transform: rotate(414.31034deg);
}
.icon .spread:nth-child(22) {
  transition-delay: 0.44s;
  transform: rotate(318.10345deg);
}
.icon .spread:nth-child(22) .unit {
  transition-delay: 0.88s;
  transform: rotate(401.89655deg);
}
.icon .spread:nth-child(23) {
  transition-delay: 0.46s;
  transform: rotate(330.51724deg);
}
.icon .spread:nth-child(23) .unit {
  transition-delay: 0.92s;
  transform: rotate(389.48276deg);
}
.icon .spread:nth-child(24) {
  transition-delay: 0.48s;
  transform: rotate(342.93103deg);
}
.icon .spread:nth-child(24) .unit {
  transition-delay: 0.96s;
  transform: rotate(377.06897deg);
}
.icon .spread:nth-child(25) {
  transition-delay: 0.5s;
  transform: rotate(355.34483deg);
}
.icon .spread:nth-child(25) .unit {
  transition-delay: 1s;
  transform: rotate(364.65517deg);
}
.icon .spread:nth-child(26) {
  transition-delay: 0.52s;
  transform: rotate(367.75862deg);
}
.icon .spread:nth-child(26) .unit {
  transition-delay: 1.04s;
  transform: rotate(352.24138deg);
}
.icon .spread:nth-child(27) {
  transition-delay: 0.54s;
  transform: rotate(380.17241deg);
}
.icon .spread:nth-child(27) .unit {
  transition-delay: 1.08s;
  transform: rotate(339.82759deg);
}
.icon .spread:nth-child(28) {
  transition-delay: 0.56s;
  transform: rotate(392.58621deg);
}
.icon .spread:nth-child(28) .unit {
  transition-delay: 1.12s;
  transform: rotate(327.41379deg);
}
.icon .spread:nth-child(29) {
  transition-delay: 0.58s;
  transform: rotate(405deg);
}
.icon .spread:nth-child(29) .unit {
  transition-delay: 1.16s;
  transform: rotate(315deg);
}

/* END .icon:hover */
.unit {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 60%;
  text-decoration: none;
  width: 80px;
  height: 80px;
  line-height: 80px;
  color: white;
  border-radius: 50%;
  transition: 0.6s;
  cursor: pointer;
}

.unit:hover img {
  height: 80px;
}

.selected img {
  height: 90px;
}

.unit.light {
  opacity: 0.2;
}

.unit.selected.light {
  opacity: 1;
}
